import gql from 'graphql-tag'

export function categoriesQuery(activeCategory: string, pageNumber: number, limit = 13) {
  const GET_ARTICLES = gql`
    query allEntries($limit: Int!, $offset: Int, $slug: String, $search: String) {
      entries(limit: $limit, offset: $offset, search: $search) {
        __typename
        ... on Blog {
          id
          uri
          title
          description
          heroImage {
            url
          }
          categories {
            title
            uri
          }
        }
      }

      entryCount(slug: $slug)
    }
  `

  const queryVariables: any = {
    limit,
    offset: limit * (pageNumber - 1)
  }

  if (activeCategory !== 'all') {
    queryVariables.slug = activeCategory
    queryVariables.search = `categories:${activeCategory}`
  }

  return {
    query: GET_ARTICLES,
    variables: queryVariables,
    limit
  }
}

export function articleQuery(articleSlug: string) {
  const GET_ARTICLE = gql`
    query($slug: String!) {
      entry(slug: $slug) {
        ... on Blog {
          title
          dateCreated
          postDate
          id
          uri
          description
          heroImage {
            url
          }
          categories {
            title
            uri
          }
          tags

          articleBody {
            ... on ArticleBodyText {
              __typename
              text {
                __typename
                totalPages
                content
              }
              position
            }

            ... on ArticleBodyHeading {
              __typename
              heading
            }

            ... on ArticleBodyQuote {
              __typename
              quote
              attribution
              position
            }

            ... on ArticleBodyNewsection {
              __typename
              sectionheading
            }

            ... on ArticleBodyPullquote {
              __typename
              pullquote
              position
            }

            ... on ArticleBodyImage {
              __typename
              image {
                url
              }
              caption {
                __typename
                totalPages
                content
              }
              alttext
              position
            }

            ... on ArticleBodySearchcta {
              __typename
              headline
              searchctaurl
              searchctatext
            }
          }
        }
      }
    }
  `

  return {
    query: GET_ARTICLE,
    variables: { slug: articleSlug }
  }
}

export const LOG_JOB_POSTING_VIEW_DATA = gql`
  mutation jobPostingLogView(
    $viewId: String!
    $loggedAt: String!
    $clickId: String
    $userParams: UserParams!
    $pageParams: PageParams!
    $jobPosting: JobPostingInput!
  ) {
    jobPostingLogView(
      input: {
        viewId: $viewId
        loggedAt: $loggedAt
        clickId: $clickId
        userParams: $userParams
        pageParams: $pageParams
        jobPosting: $jobPosting
      }
    )
  }
`

export const LOG_IMPRESSION_DATA = gql`
  mutation logImpression(
    $impression_id: String!
    $placement_context: String!
    $placement_path: String!
    $placement_platform: String!
    $location: String!
    $keyword: String!
    $filters: String!
    $url: String!
    $jobs: [ImpressionJobObject]!
    $userParams: UserParams
  ) {
    logImpression(
      impression_id: $impression_id
      placement_context: $placement_context
      placement_path: $placement_path
      placement_platform: $placement_platform
      location: $location
      keyword: $keyword
      filters: $filters
      url: $url
      jobs: $jobs
      userParams: $userParams
    )
  }
`

export const LOG_DESCRIPTION_IMPRESSION_DATA = gql`
  mutation logDescriptionImpression(
    $impression_id: String!
    $placement_context: String!
    $placement_path: String!
    $placement_platform: String!
    $location: String!
    $keyword: String!
    $filters: String!
    $url: String!
    $job: DescriptionImpressionJobObject!
    $userParams: UserParams
    $navigationParams: NavigationParams
    $pageParams: PageParams
    $screenParams: ScreenParams
    $windowParams: WindowParams
    $connectionParams: ConnectionParams
    $page_context: String!
  ) {
    logDescriptionImpression(
      impression_id: $impression_id
      placement_context: $placement_context
      placement_path: $placement_path
      placement_platform: $placement_platform
      location: $location
      keyword: $keyword
      filters: $filters
      url: $url
      job: $job
      userParams: $userParams
      navigationParams: $navigationParams
      pageParams: $pageParams
      screenParams: $screenParams
      windowParams: $windowParams
      connectionParams: $connectionParams
      page_context: $page_context
    )
  }
`
