import sanitizeHtml from 'sanitize-html'

export const abbreviateCompanyName = (companyName: string = '') => {
  let companyAbbr = ''
  const company = companyName
    .replace(/[^a-zA-Z-]/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim()

  if (company.includes(' - ')) {
    companyAbbr = company[0]
  } else if (company.includes('-')) {
    const companyAbbrArr = companyName.split('-')
    companyAbbr =
      (companyAbbrArr[0] ? companyAbbrArr[0][0] : '') +
      (companyAbbrArr[1] ? companyAbbrArr[1][0] : '')
  } else if (company) {
    const companyAbbrArr = company.split(' ')
    companyAbbr =
      (companyAbbrArr[0] ? companyAbbrArr[0][0] : '') +
      (companyAbbrArr[1] ? companyAbbrArr[1][0] : '')
  }
  return companyAbbr
}

export const rawText = (html: string) => {
  let text = sanitizeHtml(html)
  text = text.replace(/<\/{0,1}.*?>/g, ' ')
  text = text.replace(/\s\s/g, ' ')

  return text
}
