import {
  appendParams,
  capitalizeTitle,
  extractStateName,
  extractCityName,
  queryResolver
} from '../../lib/utils'
import ApolloClient from 'apollo-client'
import { LOG_IMPRESSION_DATA } from '../../graphql/query'
import { UserParams, Flags } from '../../types/types'
import { abbreviateCompanyName, rawText } from '../../components/common/JobItemUi/jobItemUtils'
import {
  NON_US_COUNTRY_LIST,
  CountryList_ISO3166_1,
  ALLOWED_COUNTRIES,
  Country
} from '../../constants/app-constants'
import { ParsedUrlQuery } from 'querystring'
import { getUserParams, parseCookies } from '../../lib/utils/cookies'
import {
  TagFilters,
  Environment,
  CompensationType,
  EntryLevel,
  PhysicalLabor,
  SiteLocation,
  JobType,
  SearchSettings,
  TagFlags,
  ExclusionClause,
  SearchJobsQueryQueryVariables,
  PostedDate,
  LocationRadius,
  TagSources,
  Job,
  Tags,
  CityData
} from '../../generated/graphql'
import { SupportedLanguages } from '~/context/LangContext'
import {
  searchMetaTitleKeywordOnly,
  searchMetaTitleKeywordAndLocation,
  searchMetaTitleLocationOnly,
  searchMetaDescriptionKeywordOnly,
  searchMetaDescriptionKeywordAndLocation,
  searchMetaDescriptionLocationOnly
} from '~/lang/langFile'

import { getLocationFieldFallback } from './location'
import { NextRouter } from 'next/router'
import { getPageType } from './url'
import { slugify } from '~/components/domain/browse/browseUtils'
export interface PageInfo {
  path: string
  title: string
  description: string
}

export function getSearchPageInfo(
  keyword: string = '',
  locationIsDefault: boolean = false,
  location: string = '',
  lang: SupportedLanguages,
  router: NextRouter,
  jobsCount?: number,
  cityData?: CityData | null
): PageInfo {
  let title = ''
  let description = ''

  const { isLocationOnlyPage, isJobTitleOnlyPage, isJobTitleAndLocationPage } = getPageType(
    router.asPath
  )

  keyword = capitalizeTitle(keyword.replace(/-jobs$/, '').replace(/-/g, ' '))
  const city: string = capitalizeTitle((cityData && cityData.city) || extractCityName(location))
  const state: string = extractStateName(location).toUpperCase()

  if (isJobTitleOnlyPage) {
    title = searchMetaTitleKeywordOnly(lang, { keyword })[0]
    description = searchMetaDescriptionKeywordOnly(lang, { keyword }, jobsCount)[0]
  } else if (isJobTitleAndLocationPage) {
    title = searchMetaTitleKeywordAndLocation(lang, { keyword, city, state }, cityData)[0]
    description = searchMetaDescriptionKeywordAndLocation(
      lang,
      { keyword, city, state },
      jobsCount
    )[0]
  } else if (isLocationOnlyPage) {
    title = searchMetaTitleLocationOnly(lang, { city, state }, jobsCount, cityData)[0]
    description = searchMetaDescriptionLocationOnly(lang, { city }, jobsCount)[0]
  } else if (keyword && (locationIsDefault || !location)) {
    // handle title on /search page
    title = searchMetaTitleKeywordOnly(lang, { keyword })[0]
    description = searchMetaDescriptionKeywordOnly(lang, { keyword }, jobsCount)[0]
  } else if (keyword && !locationIsDefault && location) {
    // handle title on /search page
    title = searchMetaTitleKeywordAndLocation(lang, { keyword, city, state }, cityData)[0]
    description = searchMetaDescriptionKeywordAndLocation(
      lang,
      { keyword, city, state },
      jobsCount
    )[0]
  }

  if (!title && (city || state)) {
    title = searchMetaTitleLocationOnly(lang, { city, state })[0]
    description = searchMetaDescriptionLocationOnly(lang, { city }, jobsCount)[0]
  }

  return {
    title,
    description,
    path: 'search'
  }
}

export const setProvidersFromFlags = (params: IJobSearchParams, flags: Flags): void => {
  let providers = []
  const country = params.searchCountry
  const searchFeeds = flags.searchFeeds as any
  for (const key in searchFeeds) {
    const feed = searchFeeds[key]
    if (
      feed.length &&
      ((feed.length === 1 && feed[0] === CountryList_ISO3166_1.ALL) || feed.includes(country))
    ) {
      providers.push(key)
    }
  }
  params.providers = providers
}

export const getTopJobProvidersFromFlags = (params: IJobSearchParams, flags: Flags): string[] => {
  let providers = []
  const country = params.searchCountry
  const topJobSearchFeeds = flags.topJobSearchFeeds as any
  for (const key in topJobSearchFeeds) {
    const feed = topJobSearchFeeds[key]
    if (
      feed.length &&
      ((feed.length === 1 && feed[0] === CountryList_ISO3166_1.ALL) || feed.includes(country))
    ) {
      providers.push(key)
    }
  }
  return providers
}

export const removeSearchProviders = (
  params: IJobSearchParams,
  providers: string | string[]
): void => {
  const providerList = Array.isArray(providers) ? providers : [providers]
  if (params.providers && params.providers.length) {
    params.providers = params.providers.filter(p => !providerList.includes(p))
  }
}

export const searchVariables = (
  params: IJobSearchParams,
  isPaidView: boolean
): SearchJobsQueryQueryVariables => {
  return {
    keyword: params.keyword,
    location: params.location,
    providers: params.providers,
    titles: params.titles,
    companys: params.companys,
    locationRadius: params.locationRadius,
    posted: params.posted,
    placeid: params.placeid,
    offset: params.offset,
    limit: params.limit,
    firstVisitId: params.firstVisitId,
    sessionId: params.sessionId,
    fbclid: params.fbclid,
    searchProviders: params.searchProviders,
    adChannel: params.adChannel,
    settings: params.settings,
    country: params.searchCountry,
    includeTags: params.includeTags,
    excludeTags: params.excludeTags,
    tagSources: params.tagSources,
    excludeBy: params.excludeBy,
    isPaidView,
    useDfsSearch: params.useDfsSearch,
    usePhraseSearch: params.usePhraseSearch
  }
}

const removeUndefinedValuesFromObject = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeUndefinedValuesFromObject(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

export interface Query {
  [key: string]: string | string[] | number | number[] | undefined
}

export interface IJobSearchParams {
  channel?: string
  location?: string
  keyword?: string
  keywordType?: string
  providers?: string[]
  limit?: number
  offset?: number
  titles?: string[]
  companys?: string[]
  locationRadius?: LocationRadius
  posted?: PostedDate
  placeid?: string
  fbclid?: string
  locationIsDefault?: boolean
  noModal?: string
  useLocalInventory?: string
  useLocalNeuvoo?: string
  firstVisitId?: string
  sessionId?: string
  adChannel?: string
  searchProviders?: {
    local?: boolean
    localInventory?: boolean
  }
  cpcBoost?: number
  settings?: SearchSettings
  country?: string
  userCountry?: string
  searchCountry?: string
  partTime?: boolean
  partTimeOnly?: boolean
  remote?: boolean
  remoteOnly?: boolean
  physicalLabor?: boolean
  physicalLaborOnly?: boolean
  hourly?: boolean
  hourlyOnly?: boolean
  entryLevel?: boolean
  entryLevelOnly?: boolean
  environment?: string[]
  nas?: string
  excludeTags?: TagFilters
  includeTags?: TagFilters
  tagSources?: TagFlags
  easyApply?: boolean
  excludeBy?: ExclusionClause
  showUnitsAsMiles?: boolean
  useDfsSearch?: boolean
  usePhraseSearch?: boolean
}

export const defaultSearchParams: IJobSearchParams = {
  partTime: true,
  partTimeOnly: false,
  remote: true,
  remoteOnly: false,
  physicalLabor: true,
  physicalLaborOnly: false,
  hourly: true,
  hourlyOnly: false,
  keyword: '',
  location: undefined,
  entryLevel: true,
  entryLevelOnly: false,
  environment: [],
  companys: [],
  titles: [],
  offset: 0,
  limit: 25,
  showUnitsAsMiles: false,
  posted: PostedDate.AnyDate,
  userCountry: Country.US
}

const validateQueryInput = (
  acceptedValues: Array<string | undefined>,
  value: string | undefined
) => {
  acceptedValues.push(undefined)
  return acceptedValues.includes(value) ? value : undefined
}

export const resolveSearchParams = (
  query: ParsedUrlQuery,
  flags: Flags,
  cookies: { [key: string]: string | undefined }
): IJobSearchParams => {
  const fromStringToBoolean = (stringValue: string | undefined): boolean | undefined =>
    stringValue === '1' ? true : stringValue === '0' ? false : undefined

  const tagSources = flags.tags
  const resolve = queryResolver(query)
  const searchParams = {
    ...defaultSearchParams,
    ...removeUndefinedValuesFromObject({
      keyword: resolve.value('q'),
      location: resolve.value('l') || getLocationFieldFallback(cookies),
      titles: resolve.arrayValue('t'),
      companys: resolve.arrayValue('c'),
      locationRadius: validateQueryInput(
        Object.values(LocationRadius),
        resolve.value('lr')?.toUpperCase()
      ),
      posted: validateQueryInput(Object.values(PostedDate), resolve.value('ps')?.toUpperCase()),
      placeid: resolve.value('pid'),
      locationIsDefault: resolve.value('dl') ? !!parseInt(resolve.value('dl')!, 10) : undefined,
      offset: resolve.value('o') ? parseInt(resolve.value('o')!, 10) : undefined,
      useLocalInventory: resolve.value('li'),
      useLocalNeuvoo: resolve.value('lin'),
      nas: resolve.value('nas'),
      partTime: fromStringToBoolean(resolve.value('fpt')),
      partTimeOnly: fromStringToBoolean(resolve.value('fpto')),
      remote: fromStringToBoolean(resolve.value('fr')),
      remoteOnly: fromStringToBoolean(resolve.value('fro')),
      physicalLabor: fromStringToBoolean(resolve.value('fp')),
      physicalLaborOnly: fromStringToBoolean(resolve.value('fpo')),
      hourly: fromStringToBoolean(resolve.value('fh')),
      hourlyOnly: fromStringToBoolean(resolve.value('fho')),
      entryLevel: fromStringToBoolean(resolve.value('fel')),
      entryLevelOnly: fromStringToBoolean(resolve.value('felo')),
      environment: resolve.arrayValue('fe') || [],
      searchCountry: validateQueryInput(Object.keys(ALLOWED_COUNTRIES), resolve.value('sc')),
      noModal: resolve.value('nm'),
      fbclid: resolve.value('fbclid') || cookies.fbclid,
      firstVisitId: cookies.firstVisitId,
      sessionId: cookies.sessionId,
      channel: resolve.value('channel'),
      adChannel: cookies.adChannel,
      settings: { explain: fromStringToBoolean(resolve.value('explain')) },
      searchProviders: {
        local: true,
        localInventory: true
      }
    })
  }

  const excludeTags: TagFilters = {
    jobType: [],
    siteLocation: [],
    physicalLabor: [],
    compensationType: [],
    environment: [],
    entryLevel: []
  }

  const includeTags: TagFilters = {
    jobType: [],
    siteLocation: [],
    physicalLabor: [],
    compensationType: [],
    environment: [],
    entryLevel: []
  }

  if (searchParams.partTime) {
    if (searchParams.partTimeOnly) {
      includeTags.jobType!.push(JobType.PartTime)
      excludeTags.jobType!.push(JobType.FullTime)
      excludeTags.jobType!.push(JobType.Contractor)
      excludeTags.jobType!.push(JobType.Other)
    }
  } else {
    excludeTags.jobType!.push(JobType.PartTime)
  }

  if (searchParams.remote) {
    if (searchParams.remoteOnly) {
      includeTags.siteLocation!.push(SiteLocation.Remote)
      excludeTags.siteLocation!.push(SiteLocation.OnSite)
      excludeTags.siteLocation!.push(SiteLocation.Other)
    }
  } else {
    excludeTags.siteLocation!.push(SiteLocation.Remote)
  }

  if (searchParams.physicalLabor) {
    if (searchParams.physicalLaborOnly) {
      includeTags.physicalLabor!.push(PhysicalLabor.PhysicalLabor)
      excludeTags.physicalLabor!.push(PhysicalLabor.Other)
    }
  } else {
    excludeTags.physicalLabor!.push(PhysicalLabor.PhysicalLabor)
  }

  if (searchParams.entryLevel) {
    if (searchParams.entryLevelOnly) {
      includeTags.entryLevel!.push(EntryLevel.EntryLevel)
      excludeTags.entryLevel!.push(EntryLevel.Other)
    }
  } else {
    excludeTags.entryLevel!.push(EntryLevel.EntryLevel)
  }

  if (searchParams.hourly) {
    if (searchParams.hourlyOnly) {
      includeTags.compensationType!.push(CompensationType.Hourly)
      excludeTags.compensationType!.push(CompensationType.Salary)
      excludeTags.compensationType!.push(CompensationType.Contract)
      excludeTags.compensationType!.push(CompensationType.Other)
    }
  } else {
    excludeTags.compensationType!.push(CompensationType.Hourly)
  }

  const allEnvironment = ['RETAIL', 'FOOD_SERVICES', 'WAREHOUSE', 'DRIVER', 'HOSPITALITY', 'OTHER']
  if (searchParams.environment && searchParams.environment.length) {
    for (const e of searchParams.environment) {
      if (allEnvironment.includes(e)) {
        excludeTags.environment!.push(e as Environment)
      }
    }
    for (const e of allEnvironment) {
      includeTags.environment!.push(Environment.Other)
      if (!searchParams.environment.includes(e)) {
        includeTags.environment!.push(e as Environment)
      }
    }
  }

  const result = {
    ...searchParams,
    excludeTags,
    includeTags,
    tagSources
  }

  return removeUndefinedValuesFromObject(result)
}

export const resolveQueryParams = (
  {
    channel,
    keyword,
    location,
    offset,
    titles,
    companys,
    locationRadius,
    posted,
    placeid,
    noModal,
    useLocalInventory,
    useLocalNeuvoo,
    country,
    searchCountry,
    partTime,
    partTimeOnly,
    remote,
    remoteOnly,
    physicalLabor,
    physicalLaborOnly,
    hourly,
    hourlyOnly,
    entryLevel,
    entryLevelOnly,
    environment
  }: IJobSearchParams,
  defaultSearchParams: IJobSearchParams
): Query => {
  const getValue = (defaultValue: any, value: any): any =>
    value !== undefined && value !== defaultValue ? value : undefined

  const getBooleanValue = (
    defaultValue: boolean | undefined,
    value: boolean | undefined
  ): string | undefined =>
    value !== undefined && value !== defaultValue ? (value ? '1' : '0') : undefined

  const query: Query = {
    a: channel ? channel : undefined,
    l: location ? location : undefined,
    q: keyword ? keyword : undefined,
    o: offset ? offset : undefined,
    t: titles && titles.length > 0 ? titles : undefined,
    c: companys && companys.length > 0 ? companys : undefined,
    sc: getValue(defaultSearchParams.searchCountry, searchCountry),
    lr: locationRadius ? locationRadius : undefined,
    ps: getValue(defaultSearchParams.posted, posted),
    pid: placeid ? placeid : undefined,
    nm: noModal ? noModal : undefined,
    fpt: getBooleanValue(defaultSearchParams.partTime, partTime),
    fpto: getBooleanValue(defaultSearchParams.partTimeOnly, partTimeOnly),
    fr: getBooleanValue(defaultSearchParams.remote, remote),
    fro: getBooleanValue(defaultSearchParams.remoteOnly, remoteOnly),
    fp: getBooleanValue(defaultSearchParams.physicalLabor, physicalLabor),
    fpo: getBooleanValue(defaultSearchParams.physicalLaborOnly, physicalLaborOnly),
    fh: getBooleanValue(defaultSearchParams.hourly, hourly),
    fho: getBooleanValue(defaultSearchParams.hourlyOnly, hourlyOnly),
    fel: getBooleanValue(defaultSearchParams.entryLevel, entryLevel),
    felo: getBooleanValue(defaultSearchParams.entryLevelOnly, entryLevelOnly),
    fe: environment ? environment : undefined,
    li: useLocalInventory ? useLocalInventory : undefined,
    lin: useLocalNeuvoo && keyword && keyword.trim().length > 0 ? useLocalNeuvoo : undefined
  }

  return removeUndefinedValuesFromObject(query)
}

export const isJobNew = (postedDate: any): boolean => {
  const THREE_DAYS = 3 * 24 * 60 * 60 * 1000
  return postedDate && +new Date() - +new Date(postedDate) < THREE_DAYS
}

export const isShowingCompareCheckbox = (query: ParsedUrlQuery, flags: Flags) => {
  const userParams = getUserParams()
  const isHomepageLanded = !!parseCookies().homepageLanded
  const fbFromUrl = !!query.fbclid || query.a === '300'
  const fbFromCookie = !!userParams.fbclid || userParams.adChannel === '300'
  const isLandingFromFacebook = fbFromUrl || fbFromCookie

  return flags.showCompare && !isHomepageLanded && !isLandingFromFacebook
}

export const isJobFeatured = (
  cpc: number | undefined | null,
  country?: string,
  service?: string | null,
  featuredCpcs?: any
): boolean => {
  return featuredCpcs !== undefined && cpc != null
    ? country?.toLowerCase() == 'us'
      ? cpc > featuredCpcs.local
      : cpc > featuredCpcs.i18n
    : false
}

enum CompanyLogoProvider {
  CLEARBIT = 'CLEARBIT',
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM'
}

export interface CompanyLogoInfo {
  companyLogo: string
  companyLogoProvider: CompanyLogoProvider
}

export const getCompanyLogoInfo = (job: Job): CompanyLogoInfo => {
  let companyLogo
  let companyLogoProvider
  if (job.companyLogo && job.companyLogo.domain) {
    companyLogo = `/company-images/v2/${job.companyLogo.domain}.png`
    companyLogoProvider = job.companyLogo.domain.endsWith('.local')
      ? CompanyLogoProvider.CUSTOM
      : CompanyLogoProvider.CLEARBIT
  } else {
    companyLogo = abbreviateCompanyName(job.company)
    companyLogoProvider = CompanyLogoProvider.TEXT
  }
  return { companyLogo, companyLogoProvider }
}

export const getJobUrlWithCompanyLogoInfo = (job: Job): string => {
  if (job.provider === 'JOBLIST_SPONSORED') {
    return job.url
  }

  const { companyLogo, companyLogoProvider } = getCompanyLogoInfo(job)
  return appendParams(
    job.url,
    'jobCompanyLogo',
    companyLogo,
    'jobCompanyLogoProvider',
    companyLogoProvider
  )
}

type JobListingDatum = {
  jobId: string
  jobTitle: string
  jobProvider: string
  jobCPC?: number | null
  jobCPCUSD?: number | null
  jobPostalCode: string | null
  jobArea1: string | null
  jobCountry: string
  jobLocality: string | null
  jobLocation: string
  jobDescription: string
  new: boolean
  featured: boolean
  tags: string[] | null
  index: number
  companyName: string
  fingerprint: string | null
  service?: string | null
}

export const logUserViewData = (
  jobs: Job[],
  searchParams: IJobSearchParams | null,
  userParams: UserParams,
  client: ApolloClient<any>,
  impressionTrackingData: {
    impression_id: string | null
    placement_context: string
    placement_path: string
    placement_platform: string
  },
  tagFlags?: TagFlags
) => {
  const {
    impression_id = '',
    placement_context = '',
    placement_path = '',
    placement_platform = ''
  } = impressionTrackingData
  const {
    location = '',
    keyword = '',
    titles = [],
    companys = [],
    locationRadius = '',
    posted = ''
  } = searchParams || {}
  const filters: string = `titles=${titles.join(',')};companys=${companys.join(
    ','
  )};locationRadius=${locationRadius};posted=${posted}`

  const jobListingsData: Array<JobListingDatum & CompanyLogoInfo> = []
  jobs.forEach((job, index) => {
    jobListingsData.push({
      jobId: job.id,
      jobProvider: job.provider,
      jobCPC: job.cpc,
      jobCPCUSD: job.cpc_usd,
      jobPostalCode: job.postal_code || null,
      jobArea1: job.area1 || null,
      jobCountry: job.country,
      jobLocality: job.locality || null,
      jobTitle: job.title,
      jobLocation: `${job.locality || ''} ${job.area1 ? ', ' + job.area1 : ''}`,
      jobDescription: rawText(job.content || '').substr(0, 170),
      new: isJobNew(job.posted_at),
      featured: isJobFeatured(job.cpc, job.country),
      tags: job.tags && tagFlags ? flattenTags(filterTagsBySource(job.tags, tagFlags)) : [],
      companyName: job.company,
      index: index + 1,
      fingerprint: job.fingerprint || null,
      service: job.service,
      ...getCompanyLogoInfo(job)
    })
  })

  client.mutate({
    mutation: LOG_IMPRESSION_DATA,
    variables: {
      location,
      keyword,
      filters,
      url: window.location.href,
      jobs: jobListingsData,
      userParams,
      impression_id,
      placement_context,
      placement_path,
      placement_platform
    }
  })
}

export function extractLocaleFromPath(req?: any, context?: any): string {
  if (!req && !context) return 'us'
  let path
  if (req) path = req.baseUrl || req.originalUrl
  if (!req && context) path = context.asPath
  const countryFromPath = path.match(new RegExp(`^/(${NON_US_COUNTRY_LIST.join('|')})`, 'i'))
  return countryFromPath ? countryFromPath[1] : 'us'
}

export function flattenTags(tags: Tags): string[] {
  let flattenedTags: string[] = []
  for (let tagValues of Object.values(tags)) {
    if (tagValues) {
      for (let tagValue of tagValues) {
        if (tagValue && tagValue != 'Unknown') {
          flattenedTags.push(tagValue)
        }
      }
    }
  }
  return flattenedTags
}

export function filterTagsBySource(tags: TagSources, tagFlags: TagFlags): Tags {
  if (!tags || !tagFlags) {
    return {}
  }
  let retval: Tags = {}
  Object.keys(tagFlags).map(tagType => {
    const tagFlagKey = tagType as keyof TagFlags
    const source = tagFlags[tagFlagKey]?.source as 'regex' | 'script' | 'manual'

    retval[tagFlagKey] =
      tags.manual && tags.manual[tagFlagKey]
        ? tags.manual[tagFlagKey]
        : tags[source]
        ? tags[source]?.[tagFlagKey]
        : []
  })

  return retval
}

export const getCanonicalUrl = (searchParams: IJobSearchParams, router: NextRouter) => {
  const { isLocationOnlyPage, isJobTitleOnlyPage, isJobTitleAndLocationPage } = getPageType(
    router.asPath
  )
  const isSearchPageWithKeywordOnly = !!router.query.q && !router.query.l
  const isSearchPageWithLocationOnly = !!router.query.l && !router.query.q
  const isSearchPageWithKeyAndLoc = !!router.query.l && !!router.query.q

  const { keyword = '', location = '', country = '' } = searchParams
  let canonicalUrlBase = country === Country.DEFAULT ? '' : `/${country}`
  const canonicalFriendlyLocation = location.replace(/,?\ /g, '-').toLowerCase()

  // Fix cases like /l/london-uk and /j/cashier/london-uk
  // where meta-tag should include uk prefix
  // to look like /uk/l/london-uk and /uk/j/cashier/london-uk
  const locationEndsWithUk = router.asPath.endsWith('-uk')
  if (locationEndsWithUk && canonicalUrlBase !== `/${Country.UK}`) {
    canonicalUrlBase = `/${Country.UK}`
  }
  // Fix cases like /l/sydney-au and /j/cashier/sydney-au
  // where meta-tag should include au prefix
  // to look like /au/l/sydney-au and /au/j/cashier/sydney-au
  const locationEndsWithAu = router.asPath.endsWith('-au')
  if (locationEndsWithAu && canonicalUrlBase !== `/${Country.AU}`) {
    canonicalUrlBase = `/${Country.AU}`
  }

  let canonicalUrl = ''
  if (isJobTitleOnlyPage || isSearchPageWithKeywordOnly) {
    return 'noIndex'
  } else if (isLocationOnlyPage || isSearchPageWithLocationOnly) {
    canonicalUrl = `${canonicalUrlBase}/l/${canonicalFriendlyLocation}`.toLowerCase()
  } else if (isJobTitleAndLocationPage || isSearchPageWithKeyAndLoc) {
    canonicalUrl = `${canonicalUrlBase}/j/${slugify(
      keyword
    )}-jobs/${canonicalFriendlyLocation}`.toLowerCase()
  }

  return canonicalUrl
}
