import React, { createContext, Reducer } from 'react'
import Router from 'next/router'
import { withRouter, SingletonRouter } from 'next/router'
import ApolloClient from 'apollo-client'
import { withApollo, WithApolloClient } from 'react-apollo'
import { ALLOWED_NON_US_COUNTRIES } from '../constants/app-constants'
import { resolveQueryParams } from '~/lib/utils/search'
import equal from 'fast-deep-equal'
import {
  IJobSearchParams as IJobSearchParamsOriginal,
  defaultSearchParams
} from '~/lib/utils/search'

import { useEnhancedReducer } from '~/components/hooks/useEnhancedReducer'
import { LocationRadius } from '~/generated/graphql'
import { useUpdateEffect } from '~/components/hooks/useUpdateEffect'
import { useFlags } from './FlagsContext'

export type IJobSearchParams = IJobSearchParamsOriginal

interface Query {
  [key: string]: string | string[] | number | number[] | undefined
}

export type State = {
  isSearchInProgress: boolean
  defaultSearchParams: IJobSearchParams
  currentSearchParams: IJobSearchParams
  tempSearchParams: IJobSearchParams
}

export type Actions = {
  setTempSearchParams: (values: Partial<IJobSearchParams>) => void
  saveSearchParams: (cb?: any) => void
  setSearchParams: (values: Partial<IJobSearchParams>) => void
  cancelSearchParams: () => void
  resetSearchParams: (cb?: any) => void
  isFiltered: () => boolean
  setSearchInProgress: (value: boolean) => void
  reloadToJobSearchPage: (params: IJobSearchParams) => void
  goToJobSearchPage: (
    params: IJobSearchParams,
    options?: { keepOffset?: boolean; scrollReset?: 'onLoad' | 'instant'; cb?: () => void }
  ) => void
  openNewTabSearchPage: (params: IJobSearchParams) => boolean
}

type Action = {
  key: string
  value: any
}

interface SearchParamsProviderProps {
  value?: IJobSearchParams
  router: SingletonRouter
  client: ApolloClient<Record<string, unknown>>
}

type ExtendedProps = WithApolloClient<SearchParamsProviderProps>

export const SearchParamsStateContext = createContext<State | undefined>(undefined)
export const SearchParamsActionsContext = createContext<Actions | undefined>(undefined)

function searchParamsReducer(state: State, action: Action): State {
  if (action.key === 'updateSearchParams') {
    const value = action.value as IJobSearchParams
    const searchParams = processSearchParams(state.defaultSearchParams, {
      ...state.tempSearchParams,
      ...value
    })

    return {
      ...state,
      defaultSearchParams: {
        ...state.defaultSearchParams,
        searchCountry: searchParams.searchCountry,
        locationRadius:
          searchParams.searchCountry === 'us'
            ? LocationRadius.Within_25Miles
            : LocationRadius.Within_25Kms
      },
      tempSearchParams: searchParams,
      currentSearchParams: searchParams
    }
  } else if (action.key === 'updateTempSearchParams') {
    const value = action.value as IJobSearchParams
    const searchParams = processSearchParams(state.defaultSearchParams, {
      ...state.tempSearchParams,
      ...value
    })

    return {
      ...state,
      tempSearchParams: searchParams
    }
  } else if (action.key === 'updateSearchInProgress') {
    const value = action.value as boolean
    return {
      ...state,
      isSearchInProgress: value
    }
  }

  return state
}

const replaceUndefinedWithDefaultValue = (defaultValues: any, newValues: any) => {
  const returnValues = JSON.parse(JSON.stringify(newValues))
  for (const key in returnValues) {
    if (returnValues[key] === undefined) {
      if (defaultValues[key] !== undefined) {
        returnValues[key] = defaultValues[key]
      } else {
        delete returnValues[key]
      }
    }
  }

  return returnValues
}

const processSearchParams = (
  defaultSearchParams: IJobSearchParams,
  newValues: Partial<IJobSearchParams>
) => {
  if (newValues.locationRadius) {
    newValues.showUnitsAsMiles =
      newValues.locationRadius?.endsWith('_MILES') ||
      (newValues.searchCountry === 'us' && newValues.locationRadius === LocationRadius.AnyLocation)
  }

  return replaceUndefinedWithDefaultValue(defaultSearchParams, newValues) as Partial<
    IJobSearchParams
  >
}

const SearchParamsProviderComponent: React.FC<ExtendedProps> = ({
  value = {},
  router,
  children
}) => {
  const flags = useFlags()
  const remoteKeywords = flags.remoteKeywords
  const newDefaultSearchParams = {
    ...defaultSearchParams,
    searchCountry: value.searchCountry,
    locationRadius:
      value.companys && value.companys.length
        ? value.searchCountry === 'us'
          ? LocationRadius.Within_100Miles
          : LocationRadius.Within_100Kms
        : value.searchCountry === 'us'
        ? LocationRadius.Within_25Miles
        : LocationRadius.Within_25Kms
  }
  const [state, dispatch, getState] = useEnhancedReducer<Reducer<State, Action>>(
    searchParamsReducer,
    {
      isSearchInProgress: false,
      defaultSearchParams: newDefaultSearchParams,
      currentSearchParams: processSearchParams(newDefaultSearchParams, {
        ...newDefaultSearchParams,
        ...value
      }),
      tempSearchParams: processSearchParams(newDefaultSearchParams, {
        ...newDefaultSearchParams,
        ...value
      })
    }
  )

  useUpdateEffect(() => {
    const newSearchParams = processSearchParams(newDefaultSearchParams, value)

    if (!equal(newSearchParams, state.currentSearchParams)) {
      setSearchParams(newSearchParams)
    }
  }, [value])

  const setSearchInProgress = (value: boolean) => {
    dispatch({
      key: 'updateSearchInProgress',
      value
    })
  }

  const setTempSearchParams = (value: Partial<IJobSearchParams>) => {
    dispatch({
      key: 'updateTempSearchParams',
      value
    })
  }

  const setSearchParams = (value: Partial<IJobSearchParams>) => {
    dispatch({
      key: 'updateSearchParams',
      value
    })
  }

  const saveSearchParams = (cb?: any) => {
    setSearchInProgress(true)
    setTimeout(() => {
      const state = getState()
      goToJobSearchPage(state.tempSearchParams, {
        cb: () => {
          if (
            router.asPath !== '/my-joblists' &&
            router.pathname !== '/joblist/[jobCollectionId]'
          ) {
            setSearchInProgress(false)
          }
          cb?.()
        }
      })
    })
  }

  const cancelSearchParams = () => {
    const state = getState()

    const newSearchParams = processSearchParams(
      state.defaultSearchParams,
      state.currentSearchParams
    )

    dispatch({
      key: 'updateTempSearchParams',
      value: newSearchParams
    })
  }

  const resetSearchParams = (cb?: any) => {
    setSearchInProgress(true)

    setTimeout(() => {
      const state = getState()
      const newSearchParams = processSearchParams(state.defaultSearchParams, {
        ...state.defaultSearchParams,
        searchCountry: state.currentSearchParams.searchCountry,
        location: state.currentSearchParams.location,
        keyword: state.currentSearchParams.keyword,
        locationRadius: undefined
      })
      goToJobSearchPage(newSearchParams, {
        cb: () => {
          setSearchInProgress(false)
          cb?.()
        }
      })
    })
  }

  const isFiltered = () => {
    return !equal(
      {
        partTime: state.defaultSearchParams.partTime,
        partTimeOnly: state.defaultSearchParams.partTimeOnly,
        remote: state.defaultSearchParams.remote,
        remoteOnly: state.defaultSearchParams.remoteOnly,
        physicalLabor: state.defaultSearchParams.physicalLabor,
        physicalLaborOnly: state.defaultSearchParams.physicalLaborOnly,
        hourly: state.defaultSearchParams.hourly,
        hourlyOnly: state.defaultSearchParams.hourlyOnly,
        locationRadius: state.defaultSearchParams.locationRadius,
        entryLevel: state.defaultSearchParams.entryLevel,
        entryLevelOnly: state.defaultSearchParams.entryLevelOnly,
        environment: state.defaultSearchParams.environment,
        companys: state.defaultSearchParams.companys,
        titles: state.defaultSearchParams.titles,
        posted: state.defaultSearchParams.posted
      },
      {
        partTime: state.currentSearchParams.partTime,
        partTimeOnly: state.currentSearchParams.partTimeOnly,
        remote: state.currentSearchParams.remote,
        remoteOnly: state.currentSearchParams.remoteOnly,
        physicalLabor: state.currentSearchParams.physicalLabor,
        physicalLaborOnly: state.currentSearchParams.physicalLaborOnly,
        hourly: state.currentSearchParams.hourly,
        hourlyOnly: state.currentSearchParams.hourlyOnly,
        locationRadius: state.currentSearchParams.locationRadius,
        entryLevel: state.currentSearchParams.entryLevel,
        entryLevelOnly: state.currentSearchParams.entryLevelOnly,
        environment: state.currentSearchParams.environment,
        companys: state.currentSearchParams.companys,
        titles: state.currentSearchParams.titles,
        posted: state.currentSearchParams.posted
      }
    )
  }

  const goToJobSearchPage = (
    params: IJobSearchParams,
    options?: { keepOffset?: boolean; scrollReset?: 'onLoad' | 'instant'; cb?: () => void }
  ) => {
    const state = getState()
    if (!options?.keepOffset) {
      params.offset = 0
    }

    dispatch({
      key: 'updateSearchParams',
      value: params
    })

    if (
      remoteKeywords &&
      remoteKeywords.length > 0 &&
      params.keyword &&
      params.keyword.length &&
      params.keyword.match(new RegExp(remoteKeywords.join('|'), 'i'))
    ) {
      if (params.keyword != state.currentSearchParams.keyword) {
        params.locationRadius = LocationRadius.AnyLocation
      }
      params.remote = true
      params.remoteOnly = true
    }

    if (params.remoteOnly === true && !state.currentSearchParams.remoteOnly) {
      params.locationRadius = LocationRadius.AnyLocation
    }

    const query = resolveQueryParams(params, state.defaultSearchParams)
    const scrollReset = options && options.scrollReset ? options.scrollReset : 'instant'

    Router.push(
      {
        pathname: '/search',
        // @ts-ignore
        query
      },
      {
        pathname: `${
          params.searchCountry && ALLOWED_NON_US_COUNTRIES[params.searchCountry]
            ? `/${params.searchCountry}`
            : ''
        }/search`,
        query
      },
      {
        // @ts-ignore
        searchCountry: params.searchCountry
      }
    ).then(() => {
      if (scrollReset === 'onLoad') {
        window.scrollTo(0, 0)
      }
      if (options?.cb) {
        options.cb()
      }
    })
    if (scrollReset === 'instant') {
      window.scrollTo(0, 0)
    }
  }

  const encodeQueryData = (data: Query): string => {
    const ret = []
    for (const d of Object.keys(data)) {
      if (data[d] && Array.isArray(data[d])) {
        for (const item of data[d] as Array<string>) {
          const value: string = encodeURIComponent(item)
          ret.push(encodeURIComponent(d) + '=' + value)
        }
      } else {
        const value: string = encodeURIComponent(data[d] ? '' + data[d] : '')
        ret.push(encodeURIComponent(d) + '=' + value)
      }
    }
    return ret.join('&')
  }

  const reloadToJobSearchPage = (params: IJobSearchParams) => {
    const query = resolveQueryParams(params, state.defaultSearchParams)
    let href = `/search?${encodeQueryData(query)}`
    if (params.searchCountry && ALLOWED_NON_US_COUNTRIES[params.searchCountry]) {
      href = `/${params.searchCountry}${href}`
    }
    window.location.href = href
    window.scrollTo(0, 0)
  }

  const openNewTabSearchPage = (params: IJobSearchParams): boolean => {
    const query = resolveQueryParams(params, state.defaultSearchParams)
    let path: string = `/search?${encodeQueryData(query)}&nm=1`
    if (params.searchCountry && ALLOWED_NON_US_COUNTRIES[params.searchCountry]) {
      path = `/${params.searchCountry}${path}`
    }
    return !!window.open(path, '_blank')
  }

  const actions = {
    setTempSearchParams,
    setSearchParams,
    saveSearchParams,
    cancelSearchParams,
    resetSearchParams,
    isFiltered,
    setSearchInProgress,
    goToJobSearchPage,
    reloadToJobSearchPage,
    openNewTabSearchPage
  }

  return (
    <SearchParamsStateContext.Provider value={state}>
      <SearchParamsActionsContext.Provider value={actions}>
        {children}
      </SearchParamsActionsContext.Provider>
    </SearchParamsStateContext.Provider>
  )
}

function useSearchParamsState() {
  const state = React.useContext(SearchParamsStateContext)

  if (state === undefined) {
    throw new Error('useSearchParamsState must be used within a SearchParamsProvider')
  }

  return state
}

function useSearchParamsActions() {
  const actions = React.useContext(SearchParamsActionsContext)

  if (actions === undefined) {
    throw new Error('useSearchParamsActions must be used within a SearchParamsProvider')
  }

  return actions
}

function useSearchParams(): [State, Actions] {
  return [useSearchParamsState(), useSearchParamsActions()]
}

interface ConsumerProps {
  children: (state: State | undefined, actions: Actions | undefined) => React.ReactNode
}
const SearchParamsConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <SearchParamsStateContext.Consumer>
      {state => {
        if (state === undefined) {
          throw new Error('SearchParamsConsumer must be used within a SearchParamsProvider')
        }
        return (
          <SearchParamsActionsContext.Consumer>
            {actions => {
              if (actions === undefined) {
                throw new Error('SearchParamsConsumer must be used within a SearchParamsProvider')
              }
              return children(state, actions)
            }}
          </SearchParamsActionsContext.Consumer>
        )
      }}
    </SearchParamsStateContext.Consumer>
  )
}

const SearchParamsProvider = withRouter(
  withApollo<SearchParamsProviderProps>(SearchParamsProviderComponent)
)

export {
  SearchParamsProvider,
  SearchParamsConsumer,
  useSearchParams,
  useSearchParamsState,
  useSearchParamsActions
}
